import React from 'react';
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'

const BigIdeaPage = ({data}) => {
    const page = data.strapiPageBigIdea

    const p = page.Paragraph

    const getWordCount = () => {
        var count = 0;

        p.forEach((value) => count += value.Text.trim().split(" ").length)

        return count;
    }

    const CallToAction = page.CallToAction && (
        <section className="call-to-action secondary-b">
          <div className="content extra column">
            <div className="header space-between">
              <div className="title">
                <h4>{ page.CallToAction.Title }</h4>
                <h3 dangerouslySetInnerHTML={{ __html: page.CallToAction.Tagline }} />
              </div>
    
              <Link to={ page.CallToAction.ButtonLink } className="button inverse mob-icon">
                <i className="fas fa-chevron-right" />
                <span>{ page.CallToAction.ButtonText }</span>
              </Link>
            </div>
          </div>
        </section>
      )

    return (
        <Layout colorTheme="secondary-b" bgColorTheme="white">
            <main id="big-idea">
                <section className="hero white">
                    <div className="content extra">
                        <div className="tagline column">
                            <h1 style={{maxWidth: 370}}>{ page.Hero.Header.replace("{wordCount}", getWordCount()) }</h1>
                            <p>{ p[0].Text }</p>
                            <a href="#content" className="button icon hide-mobile"><i className="fas fa-chevron-down" /></a>
                        </div>
                        <div className="alt">
                            <div className="image-wrapper">
                                <div className="image" style={{ top: '-14%', left: '-20%', backgroundSize: 'contain', backgroundImage: ["url(", "../images/idea_hero.png", ")"].join("")}}></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="content" className="bright comp-pad">
                    <div className="content column count" style={{ maxWidth: 580 }}>
                        { p.slice(0, 2).map((value) => <p key={value.id}>{ value.Text }</p>) }
                    </div>
                </section>

                <section className="white">
                    <div className="content center spaced-2x">
                        <h1>{ page.Header }</h1>
                    </div>
                    <div className="content column count" style={{ maxWidth: 580 }}>
                        { p.slice(2, 5).map((value) => <p key={value.id}>{ value.Text }</p>) }
                    </div>
                </section>

                <section className="white no-pad">
                    <div className="content column">
                        <div className="grid col-2 mob-wrap">
                            {
                                page.Section2Images.map((image) => 
                                    <div key={ image.id } className="image-responsive ratio-3-4 round">
                                        <div className="wrapper">
                                            <div className="image" style={{ backgroundImage: `url(${ process.env.CONTENT_BASE}${ image.url })`, backgroundPosition: 'center center' }} />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </section>

                <section className="white">
                    <div className="content column spaced-2x count" style={{ maxWidth: 580 }}>
                        { p.slice(5).map((value) => <p key={value.id}>{ value.Text }</p>) }
                        <div className="bar secondary-d" />
                    </div>
                </section>

                <section className="values bright">
                    <div className="content">
                        <div className="description">
                            <h2>{ page.CardHeader }</h2>
                            <p className="big">{ page.CardBody }</p>
                        </div>
                        <div className="column card-view">
                            {
                                page.Card.map((card) =>
                                    <div key={ card.Header } className="card">
                                        <h5>{ card.Header }</h5>
                                        <p>{ card.Body }</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </section>

                { CallToAction }
            </main>
        </Layout>
    )
}

export const query = graphql`
    query BigIdea {
        strapiPageBigIdea {
            Hero {
                Header
                Tagline
                Button {
                    Icon
                    Link
                    ButtonType
                    HideMobile
                }
            }
            Paragraph {
                id
                Text
            }
            Header
            Section2Images {
                id
                url
            }
            CardHeader
            CardBody
            Card {
                id
                Header
                Body
            }

            CallToAction {
                Title
                Tagline
                ButtonText
                ButtonLink
            }
        }
    }`

export default BigIdeaPage;
